<template>

    <Navbar :pagetitle="pagetitle" :navitems="navitems"/>
    <Header :texts="header"/>
    <Card   :cards="cards" />
    <div class="modest-gallery bg-med row d-flex justify-content-between" id="oldApps">
        <Gallery  :data="ydl"    />
        <Gallery  :data="reSize" />
    </div>

    <Footer />

</template>
<script>
import Navbar   from  '@/components/Navbar'
import Header   from  '@/components/Header'
import Footer   from  '@/components/Footer'
import Card     from  '@/components/ModernCard'
import Gallery  from  '@/components/ModernGallery'

export default({
    name: 'Other',
    components: { Navbar, Header, Card, Gallery, Footer },
    data(){ return {
        pagetitle:'desktop|post-training',
        navitems: [ {
                title: '20|21',
                route: 'other',
            }, {
                title: 'preDegree',
                route: 'older',
            }
        ],
        cards: [ 
            {icon: 'fas fa-money-bill-wave', title: 'free'},
            {icon: 'far fa-file-code', title: 'modern'},
            {icon: 'fas fa-cogs', title: 'useful'},
            ],
        header: ['Applications','for Windows','& MacOS'],
        ydl: {
            no: 4,
            title: 'YDL',
            subtitle: 'free Youtube Download Manager',
            texts: ['Copy URL.', 'Press Paste.', 'Click Start.'],
            buttons: [ 
                { link: '', descr: 'get installer'},
                { link: '', descr: 'get portable'}
            ],
        },
        reSize: {
            no: 5,
            title: 'reSize',
            subtitle: 'A tribute to Brad Traversy.',
            texts: [
                'Drop or select image.',
                'Choose quality (..try ~90..).',
                'Done.'
            ],
            buttons: [{ link: 'storage/resize.rar', descr: 'get portable'} ,],
        }
    } 
    },
})
</script>
<style>


</style>